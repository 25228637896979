.filtered-customer-table .p-datatable-thead tr {
  @apply rounded-xl;
}

.filtered-customer-table .p-datatable-thead tr th:first-child {
  @apply rounded-s-xl;
}

.filtered-customer-table .p-datatable-thead tr th:last-child {
  @apply rounded-e-xl;
}

.p-paginator {
  @apply border-none;
}
