.fontFamily {
  font-family: "Petrona", serif;
}

.tableBg {
  background: linear-gradient(
      0deg,
      rgba(161, 233, 160, 0.456474),
      rgba(161, 233, 160, 0.456474)
    ),
    linear-gradient(0deg, #d9d9d9, #d9d9d9);
  box-shadow: 0px 3.165938377380371px 3.165938377380371px 0px #00000040;
}
