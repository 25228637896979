.fontFamily {
  font-family: "Petrona", serif;
}

.detailsContainer {
  @apply flex flex-col gap-4 md:flex-row;
}

.detailsContainerDiv {
  @apply w-full md:w-1/2;
}

.detailsTitle {
  @apply text-xl font-bold;
}

.detailsContent {
  @apply w-full py-3 pl-2 font-bold text-black rounded-lg shadow-lg md:w-11/12 bg-slate-200;
}
