@import url("https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Petrona:wght@100;200;300;400;500;600;700;800;900&display=swap");

.login-title {
  font-family: "Baskervville", serif;
}

.login-form .p-password input {
  @apply w-full rounded-3xl;
}

.login-form .p-password i {
  display: flex;
}
