@import url("https://fonts.googleapis.com/css2?family=Overlock:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Petrona:wght@100;200;300;400;500;600;700;800;900&display=swap");

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

:root {
  --main-green-background: #3a5d4c;
  --light-text: #fff;
}

body {
  font-family: "Overlock", sans-serif;
}

/* Table Style */

table {
  border-spacing: 0 8px !important;
  border-collapse: separate !important;
  border: none;
  padding: 0 8px;
}

table tr {
  background-color: #ffeded;
}

.p-column-title {
  @apply text-sm;
}

.p-paginator {
  @apply bg-transparent;
}

.p-datatable .p-datatable-header {
  @apply bg-[#ffeded] border-none p-0 m-0;
}

.p-datatable .p-datatable-thead > tr > th {
  @apply bg-[#ffeded] border-none p-0 py-4;
}

.p-column-header-content {
  @apply flex items-center justify-center text-lg font-bold leading-5;
}

.p-datatable-tbody tr {
  /* background-color: #c4e1d8; */
  @apply bg-[#c4e1d8];
}

.p-datatable-tbody tr td {
  @apply text-center p-0 font-semibold text-base leading-4 text-[#0D6047];
}

.p-datatable-tbody tr td:first-child {
  @apply rounded-s-xl;
}

.p-datatable-tbody tr td:last-child {
  @apply rounded-e-xl;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-width: 1.5px;
}

/* Dialog Style */
.p-dialog-header {
  @apply bg-[--main-green-background] text-[#BCAD67];
}

.p-dialog-content {
  @apply bg-[--main-green-background] text-[#BCAD67];
}

.p-dialog-footer {
  @apply bg-[--main-green-background] text-[#BCAD67];
}

.p-dialog-title {
  @apply flex items-center justify-center w-full;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-datepicker table td > span.p-highlight {
  @apply bg-[#c4e1d8] text-[#BCAD67];
}

.p-input-icon-right i {
  display: flex;
}

/* multiselect */
.p-multiselect-label.p-placeholder {
  padding: 0.5rem 0.5rem;
}
.p-tabview .p-tabview-panels {
  background-color: transparent;
}
.p-tabview .p-tabview-nav {
  background-color: transparent;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent;
}

/* Filter */
.p-column-header-content {
  @apply w-3/4 mx-auto text-center;
}


/* Dialog Z-index */
.p-image-mask .p-component-overlay .p-component-overlay-enter {
  z-index: 2000;
}

.p-dialog-mask .p-dialog-center .p-component-overlay .p-component-overlay-enter .p-dialog-draggable .p-dialog-resizable {
  z-index: 1100;
}