.fontFamily {
  font-family: "Petrona", serif;
}

.tableBg {
  background: linear-gradient(
      0deg,
      rgba(161, 233, 160, 0.456474),
      rgba(161, 233, 160, 0.456474)
    ),
    linear-gradient(0deg, #d9d9d9, #d9d9d9);
  box-shadow: 0px 3.165938377380371px 3.165938377380371px 0px #00000040;
}

.detailsContainer {
  @apply flex flex-row;
}

.detailsContainerDiv {
  @apply w-1/2;
}

.detailsTitle {
  @apply text-xl font-bold;
}

.detailsContent {
  @apply w-11/12 py-3 pl-2 font-bold text-black rounded-lg shadow-lg bg-slate-200;
}
